import { SVGProps } from 'react'

export const MailboxIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='43'
    height='46'
    viewBox='0 0 43 46'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M38.7 12.5455H17.2V25.0909H12.9V8.36364H25.8V0H8.6V12.5455H4.3C1.935 12.5455 0 14.4273 0 16.7273V41.8182C0 44.1182 1.935 46 4.3 46H38.7C41.065 46 43 44.1182 43 41.8182V16.7273C43 14.4273 41.065 12.5455 38.7 12.5455Z'
      fill='currentColor'
    />
  </svg>
)
