type BaseFooterTextProps = {
  text: string
  linkText: string
}

type FooterTextLinkProps = BaseFooterTextProps & {
  linkOnClick?: never
  linkHref: string
}

type FooterTextButtonProps = BaseFooterTextProps & {
  linkOnClick: () => void
  linkHref?: string
}

type FooterTextProps = FooterTextLinkProps | FooterTextButtonProps

export const FooterText = ({
  text,
  linkText,
  linkHref,
  linkOnClick,
}: FooterTextProps) => {
  const linkClasses =
    'text-lue-purple-400 underline inline-block pl-1 cursor-pointer text-base'

  return (
    <p className='text-base text-black font-normal mt-2 text-center'>
      {text}
      <span>
        {linkHref ? (
          <a href={linkHref} className={linkClasses}>
            {linkText}
          </a>
        ) : (
          <button type='button' onClick={linkOnClick} className={linkClasses}>
            {linkText}
          </button>
        )}
      </span>
    </p>
  )
}
