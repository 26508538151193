import React, { useCallback, useRef, useState } from 'react'
import * as yup from 'yup'
import { PadlockIcon } from '../Icons/PadlockIcon'
import { FormPageWrapper } from './FormPageWrapper'
import { FormMessage } from './FormMessage'
import { useFormik } from 'formik'
import { Button } from './Button'
import { FooterText } from './FooterText'
import Cognito from '../../cognito'
import { VerificationCodeInput } from './VerificationCodeInput'
import { PasswordInputGroup } from '../Inputs/PasswordInputGroup'
import { InputGroup } from '../Inputs/InputGroup'
import { EmailIcon } from 'common/components/Icons/EmailIcon'

const schema = yup.object().shape({
  password: yup.string().required('Password is required'),
  confirm_password: yup
    .string()
    .required('Password confirmation is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
})

const ResetPasswordPage = () => {
  const codeInputRef = useRef<HTMLInputElement>(null)
  const [isResending, setIsResending] = useState<boolean>(false)
  const [formError, setFormError] = useState<string>('')
  const { email } = Utils.fromParam()

  const resendEmail = useCallback(() => {
    setFormError('')
    setIsResending(true)
    Cognito.resendConfirmSignUp(email)
  }, [email])

  const { handleBlur, handleSubmit, handleChange, errors, setTouched, values } =
    useFormik({
      validateOnBlur: false,
      validateOnChange: false,
      initialValues: {
        email: email ?? '',
        password: '',
        confirm_password: '',
      },
      validationSchema: schema,
      onSubmit: async (values) => {
        try {
          await Cognito.changePassword(
            values.email,
            codeInputRef.current.value,
            values.password,
          )
        } catch (err) {
          setFormError(err.message)

          return
        }

        document.location.href = '/reset-password/success'
      },
    })

  const onSubmit = (e) => {
    setIsResending(false)
    const newTouched = {}
    Object.keys(errors).map((v) => {
      newTouched[v] = true
    })
    setTouched(newTouched)
    handleSubmit(e)
  }

  return (
    <FormPageWrapper title='Create New Password' Icon={PadlockIcon}>
      <p className='text-black text-center text-zinc-600 text-base'>
        We’ve sent you a reset code
        {email && (
          <>
            {' '}
            to <span className='font-bold text-black text-base'>{email}</span>
          </>
        )}
        .{' '}
      </p>
      <p className='mb-4 text-black text-center text-zinc-600 text-base'>
        Please input the code below, then choose your new password.
      </p>

      <FormMessage type='error'>{formError}</FormMessage>

      {isResending && (
        <FormMessage type='success'>
          The verification email has been resent.
        </FormMessage>
      )}

      <form onSubmit={onSubmit}>
        <VerificationCodeInput ref={codeInputRef} />
        <InputGroup
          id='email'
          label='Email'
          name='email'
          theme='grey'
          className={email ? 'hidden' : ''} // Hide if email passed as query param
          imgBefore={<EmailIcon />}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email.trim()}
          error={typeof errors.email === 'string' && errors.email}
          placeholder='Email Address'
        />
        <PasswordInputGroup
          id='password'
          label='Password'
          name='password'
          theme='grey'
          autoComplete='password'
          imgBefore={<PadlockIcon />}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          error={errors.password}
          className='my-5'
          type='password'
          placeholder='Password'
        />
        <PasswordInputGroup
          id='password'
          label='Password'
          name='confirm_password'
          theme='grey'
          autoComplete='new-password'
          imgBefore={<PadlockIcon />}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.confirm_password}
          error={errors.confirm_password}
          className='my-5'
          type='password'
          placeholder='Confirm Password'
        />
        <Button type='submit'>Reset Password</Button>
      </form>
      <div className='mt-4'>
        <FooterText
          text="Didn't get the email?"
          linkOnClick={resendEmail}
          linkText='Click to resend'
        />
        <FooterText text='Back to' linkHref='/login' linkText='Sign In' />
      </div>
    </FormPageWrapper>
  )
}

export default ResetPasswordPage
