import  { useEffect, useMemo, useState } from "react";
import { GetTheAppButton } from "components/AppStore/GetTheAppButton";
import { CardCarousel } from "components/CardCarousel";
import { TYPES_OF_LAWYER } from "data/typeOfLawyerData";
import { Settings } from "react-slick";
import cn from "classnames";
import { InfoIconPopup } from "common/components/Popups/InfoIconPopup";
import vectorBgRow from '../../assets/vector-bg-row.png';
import useAreasOfPractice from "common/providers/useAreasOfPractice";
import { BaseContainer } from "components/BaseContainer";

const SLIDER_SETTINGS: Settings = {
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 1028,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
  ]
};

export const LawCardsSection = () => {
  const [typeOfLawyer, setTypeOfLawyer] = useState(TYPES_OF_LAWYER[0].value);
  const [openPopup, setOpenPopup] = useState<number | null>(null);
  const { getAreasOfPractice, areasOfPractice = [] } = useAreasOfPractice();

  useEffect(() => {
    getAreasOfPractice({ featured: true });
  }, []);

  // Attempt to match the card titles with the names of returned areas of practice.
  // Filter out any where we cannot find a match.
  const carouselData = useMemo(() =>
    (areasOfPractice ?? [])
      .map((areaOfPractice) => {
        const count = {
          Solicitor: areaOfPractice.lawyer_count,
          Barrister: areaOfPractice.barrister_count,
        }[typeOfLawyer];

        return {
          title: areaOfPractice.name,
          href: `./dashboard?type=${typeOfLawyer}&field=${areaOfPractice.id}`,
          img: areaOfPractice.card_image,
          description: Number.isFinite(count)
            ? `${count} Result${count === 1 ? '' : 's'}`
            : undefined,
        };
      }).filter(x => x),
    [areasOfPractice, typeOfLawyer]
  );

  return (
    <section
      className="pb-6 lg:pt-6 overflow-hidden bg-no-repeat lg:bg-contain bg-bottom"
      style={{
        backgroundImage: `url('${vectorBgRow}')`,
      }}
    >
      <BaseContainer size="md">
        <div className="lg:grid lg:grid-cols-10 mb-[160px] lg:mb-2">
          <div className="lg:col-span-4 xl:col-span-3">
            <h2 className="text-black font-bold text-3xl xl:text-4xl text-center lg:text-left">
              Find a regulated lawyer
            </h2>
            <ul className="grid grid-auto-rows grid-cols-2 mt-5 mb-8 lg:mb-0 gap-4 lg:gap-2 lg:mr-4 mx-4 lg:ml-0">
              {TYPES_OF_LAWYER.map((item, i) => (
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      setTypeOfLawyer(item.value)
                    }}
                    className={cn(
                      'relative rounded-full text-lg py-3 px-3 w-full flex justify-center whitespace-nowrap shadow',
                      item.value === typeOfLawyer ? 'bg-lue-blue-700 text-white' : 'bg-white text-black',
                    )}
                  >

                    {item.label}
                    {item.infoText && (
                      <div className="absolute right-4">
                        <InfoIconPopup
                          text={item.infoText}
                          onOpen={() => { setOpenPopup(i); }}
                          onClose={() => { setOpenPopup(null); }}
                          isOpen={openPopup === i}
                        />
                      </div>
                    )}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="lg:col-span-6 xl:col-span-7">
            <CardCarousel
              isSlim
              type="default"
              data={carouselData}
              sliderSettings={SLIDER_SETTINGS}
            />
          </div>
        </div>
        <div className="hidden lg:flex justify-end mb-12">
          <GetTheAppButton />
        </div>
      </BaseContainer>
    </section>
  )
};
