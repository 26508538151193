import instagramLogo from "../assets/Social/instagram.svg";
import twitterLogo from "../assets/Social/twitter.svg";
import linkedinLogo from "../assets/Social/linkedin.svg";

type SiteFooterSocialLinksProps = {
  className: string;
}

export const SiteFooterSocialLinks = ({
  className,
}: SiteFooterSocialLinksProps) => (
  <div className={className}>
    <a href="https://www.instagram.com/legalutopia/">
      <img
        src={instagramLogo}
        width="35px"
        height="35px"
        alt="Instagram logo"
      />
    </a>
    <a href="https://twitter.com/ailegalutopia">
      <img
        src={twitterLogo}
        width="35px"
        height="35px"
        alt="Twitter logo"
      />
    </a>
    <a href="https://www.linkedin.com/company/legal-utopia">
      <img
        src={linkedinLogo}
        width="35px"
        height="35px"
        alt="LinkedIn logo"
      />
    </a>
  </div>
);
