import { Input } from './Input'
import { InputErrorText } from './InputErrorText'
import { InputWrapper } from './InputWrapper'
import cn from 'classnames'
import { InputHTMLAttributes, ReactElement } from 'react'

export type InputGroupProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string
  id: string
  isFirst?: boolean
  className?: string
  imgBefore?: ReactElement | string
  imgAfter?: ReactElement | string
  error?: string
  theme?: 'default' | 'grey'
  isLabelVisible?: boolean
}

export const InputGroup = ({
  label,
  id,
  isFirst,
  className,
  imgBefore,
  imgAfter,
  error,
  theme = 'default',
  isLabelVisible,
  ...restProps
}: InputGroupProps) => {
  return (
    <div className={className}>
      <label
        className={isLabelVisible ? 'font-medium mb-1 block' : 'hidden'}
        htmlFor={id}
      >
        {label}
      </label>
      <InputWrapper
        isFirst={isFirst}
        imgBefore={imgBefore}
        imgAfter={imgAfter}
        hasError={!!error}
        theme={theme}
      >
        <Input
          className={cn(imgBefore ? 'pl-2 lg:pl-3 pr-5' : 'px-5')}
          id={id}
          {...restProps}
        />
      </InputWrapper>
      {error && <InputErrorText>{error}</InputErrorText>}
    </div>
  )
}
