import cn from 'classnames'
import { ButtonHTMLAttributes } from 'react'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  secondary?: boolean
}

export const Button = ({
  children,
  className,
  disabled,
  secondary,
  ...restProps
}: ButtonProps) => (
  <button
    type='button'
    {...restProps}
    disabled={disabled}
    className={cn(
      'rounded-full text-lg py-2 px-8 w-full flex items-center justify-center whitespace-nowrap transition-colors',
      disabled
        ? 'bg-zinc-200 cursor-not-allowed'
        : 'bg-lue-purple-400 hover:bg-lue-purple-500 cursor-pointer',
      secondary
        ? 'bg-white text-black text-semibold border border-gray-300'
        : 'bg-lue-purple-400 text-white',
      className,
    )}
  >
    {children}
  </button>
)
