import { SVGProps } from 'react'

export const KeyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='47'
    height='46'
    viewBox='0 0 47 46'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M24.7432 19.1667C23.1716 14.7008 18.9166 11.5 13.9141 11.5C7.5699 11.5 2.41406 16.6558 2.41406 23C2.41406 29.3442 7.5699 34.5 13.9141 34.5C18.9166 34.5 23.1716 31.2992 24.7432 26.8333H33.0807V34.5H40.7474V26.8333H44.5807V19.1667H24.7432ZM13.9141 26.8333C11.8057 26.8333 10.0807 25.1083 10.0807 23C10.0807 20.8917 11.8057 19.1667 13.9141 19.1667C16.0224 19.1667 17.7474 20.8917 17.7474 23C17.7474 25.1083 16.0224 26.8333 13.9141 26.8333Z'
      fill='currentColor'
    />
  </svg>
)
