import { ComponentType, ReactNode, SVGProps, useContext } from 'react'
import { LinkContext } from '../../providers/LinkProvider'
import { AuthPageContext } from '../../providers/AuthPageProvider'

type FormPageWrapperProps = {
  title: string
  Icon?: ComponentType<SVGProps<SVGSVGElement>>
  children: ReactNode
}

export const FormPageWrapper = ({
  title,
  Icon,
  children,
}: FormPageWrapperProps) => {
  const { Link } = useContext(LinkContext)
  const { backgroundImageSrc, logoSrc, tagline, logoBackLink } =
    useContext(AuthPageContext)

  return (
    <main
      className='flex flex-col md:flex-row bg-cover min-h-screen'
      style={{ backgroundImage: `url('${backgroundImageSrc}')` }}
    >
      <div className='flex flex-col flex-grow items-center md:justify-center w-full md:max-w-[400px] lg:max-w-[500px] xl:max-w-[700px] px-6 lg:px-8 xl:px-12 pt-12 pb-4 md:py-20 bg-white rounded-t-3xl md:rounded-l-none md:rounded-r-3xl mt-6 md:mt-0 order-last md:order-none'>
        {Icon && <Icon width='38' height='38' />}
        <h1 className='font-bold text-2xl md:text-3xl text-black mt-4 text-center'>
          {title}
        </h1>
        <div className='w-full rounded-full pt-6 py-4'>{children}</div>
      </div>
      <div className='flex flex-col items-center justify-center w-full'>
        <Link href={logoBackLink}>
          <div className='flex flex-row items-center md:whitespace-nowrap mt-12 md:mt-0 cursor-pointer'>
            <img
              className='max-w-[40px] md:max-w-[50px] mr-2'
              src={logoSrc}
              width='50'
              height='50'
              alt='Legal Utopia logo'
            />
            <span className='font-medium leading-none tracking-wide text-xl text-white'>
              Legal Utopia
            </span>
          </div>
        </Link>
        <p className='hidden md:flex font-bold text-white text-4xl xl:text-5xl flex-col items-center'>
          <span className='mt-4'>{tagline},</span>
          <span className='text-lawBlue mt-4'>Fast.</span>
        </p>
      </div>
    </main>
  )
}
