import { SVGProps } from 'react'

export const ProfileFilledIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='15'
    height='15'
    viewBox='0 0 15 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M7.5 7.5C9.43375 7.5 11 5.93375 11 4C11 2.06625 9.43375 0.5 7.5 0.5C5.56625 0.5 4 2.06625 4 4C4 5.93375 5.56625 7.5 7.5 7.5ZM7.5 9.25C5.16375 9.25 0.5 10.4225 0.5 12.75V14.5H14.5V12.75C14.5 10.4225 9.83625 9.25 7.5 9.25Z'
      fill='currentColor'
    />
  </svg>
)
