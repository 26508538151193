import Checkbox, { CheckboxProps } from 'rc-checkbox'
import { ReactNode } from 'react'
import { InputErrorText } from './InputErrorText'

type CheckboxGroupProps = CheckboxProps & {
  label: ReactNode
  id: string
  className?: string
  error?: string
}

export const CheckboxGroup = ({
  label,
  id,
  className,
  error,
  ...restProps
}: CheckboxGroupProps) => (
  <div className={className}>
    <div>
      <label htmlFor={id}>
        <Checkbox id={id} {...restProps} className='pr-2' />
        {label}
      </label>
      {error && <InputErrorText>{error}</InputErrorText>}
    </div>
  </div>
)
