import React, { useContext, useState } from 'react'
import { useAuth } from '../../providers/useAuth'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { EmailIcon } from '../Icons/EmailIcon'
import { SocialSignInButtons } from './SocialSignInButtons'
import { InputGroup } from '../Inputs/InputGroup'
import { FormPageWrapper } from './FormPageWrapper'
import { Button } from './Button'
import { FooterText } from './FooterText'
import { FormMessage } from './FormMessage'
import { PasswordInputGroup } from '../Inputs/PasswordInputGroup'
import { LinkContext } from '../../providers/LinkProvider'
import { ProfileCircleIcon } from '../Icons/ProfileCircleIcon'
import { PadlockIcon } from '../Icons/PadlockIcon'
import { AuthPageContext } from '../../providers/AuthPageProvider'
import { AppState } from 'common/state-type'
import { encodeAuthState } from 'common/utils/auth'
import VerifyMFAForm from './VerifyMfaForm'

type LoginPageProps = {
  onSuccess?: (data: any) => void
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Email must be valid')
    .required('Email address is required'),
  password: yup.string().required('Password is required'),
})

const LoginPage = ({ onSuccess }: LoginPageProps) => {
  const { hasSocialSignIn } = useContext(AuthPageContext)
  const { login, userLoading } = useAuth()
  const [userError, setError] = useState<boolean>(false)
  const [requiresMfa, setRequiresMfa] = useState<boolean>(false)
  const { Link } = useContext(LinkContext)
  const { handleBlur, handleSubmit, handleChange, errors, setTouched, values } =
    useFormik({
      validateOnBlur: false,
      validateOnChange: false,
      initialValues: {
        email: '',
        password: '',
      },
      validationSchema: schema,
      onSubmit: (values) => {
        login(
          {
            username: values.email,
            password: values.password,
          },
          {
            onSuccess: (cognitoUser: AppState['user']) => {
              if (cognitoUser?.challengeName === 'NEW_PASSWORD_REQUIRED') {
                document.location = `${
                  document.location.origin
                }/reset-password?email=${encodeURIComponent(values.email)}`

                return
              }

              if (cognitoUser?.challengeName === 'SOFTWARE_TOKEN_MFA') {
                setRequiresMfa(true)

                return
              }

              onSuccess?.(cognitoUser)
            },
            onError: (e) => {
              if (e?.includes('not confirmed')) {
                document.location = `${
                  document.location.origin
                }/verify-email?state=${encodeAuthState({
                  email: values.email,
                  password: values.password,
                })}`

                return
              }

              // MFA verification required
              if (e?.includes('not authenticated')) {
                return
              }

              setError(e)
            },
          },
        )
      },
    })

  const onSubmit = (e) => {
    const newTouched = {}
    Object.keys(errors).map((v) => {
      newTouched[v] = true
    })
    setTouched(newTouched)
    handleSubmit(e)
  }

  if (requiresMfa) {
    return (
      <FormPageWrapper title='Two-Factor Authentication'>
        <div className='flex flex-col items-center'>
          <VerifyMFAForm onSuccess={API.loginRedirect} />
        </div>
      </FormPageWrapper>
    )
  }

  return (
    <FormPageWrapper title='Sign In' Icon={ProfileCircleIcon}>
      <FormMessage type='error'>{userError}</FormMessage>
      <form onSubmit={onSubmit}>
        <InputGroup
          id='email'
          label='Email'
          name='email'
          theme='grey'
          imgBefore={<EmailIcon />}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email.trim()}
          error={errors.email}
          placeholder='Email Address'
        />
        <PasswordInputGroup
          id='password'
          label='Password'
          name='password'
          theme='grey'
          imgBefore={<PadlockIcon />}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          error={errors.password}
          className='my-5'
          type='password'
          autoComplete='current-password'
          placeholder='Password'
        />
        <div className='text-right mb-4'>
          <Link
            href='/forgot-password'
            className='cursor pointer text-lue-purple-400'
          >
            Forgotten password?
          </Link>
        </div>

        <Button type='submit' disabled={userLoading}>
          Login
        </Button>
      </form>

      <div className='mt-4'>
        {hasSocialSignIn && (
          <>
            <p className='text-center mb-4'>or</p>
            <SocialSignInButtons />
          </>
        )}
        <FooterText
          text="Don't have an account?"
          linkHref='/signup'
          linkText='Sign Up'
        />
      </div>
    </FormPageWrapper>
  )
}

export default LoginPage
