import { HubSection } from "./CWP Sections/HubSection";
import { AboutSection } from "./CWP Sections/AboutSection";
import { HeroSection } from "./CWP Sections/HeroSection";
import { LawCardsSection } from "./CWP Sections/LawCardsSection";
import { BannerSection } from "./CWP Sections/BannerSection";
import { LegalDocuments } from "./LegalDocuments";
import { SiteFooter } from "./SiteFooter";

const LandingPage = () => (
  <>
    <HeroSection />
    <LawCardsSection />
    <LegalDocuments />
    <BannerSection />
    <HubSection />
    <AboutSection />
    <SiteFooter />
  </>
);

export default LandingPage;
