import React, { ReactNode, useCallback } from 'react'
import { FormPageWrapper } from './FormPageWrapper'
import { Button } from './Button'
import { TickCircleIcon } from '../Icons/TickCircleIcon'

type SuccessPageWrapperProps = {
  children: ReactNode
}

const SuccessPageWrapper = ({ children }: SuccessPageWrapperProps) => {
  const onSignInClick = useCallback(() => {
    document.location.href = '/login'
  }, [])

  return (
    <FormPageWrapper title='Success' Icon={TickCircleIcon}>
      <p className='mb-6 text-black text-center text-zinc-600 text-base'>
        {children}
      </p>

      <Button onClick={onSignInClick}>Sign In</Button>
    </FormPageWrapper>
  )
}

export default SuccessPageWrapper
