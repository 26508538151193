import React, {
  FormEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import useVerifyMfa from 'common/providers/useVerifyMfa'
import useConfirmSignin from '../../providers/useConfirmSignin'
import { FormMessage } from 'common/components/Authentication/FormMessage'
import { VerificationCodeInput } from 'common/components/Authentication/VerificationCodeInput'
import { Button } from './Button'

interface VerifyMFAFormProps {
  mfaSetup?: boolean
  onSuccess: () => void
  onSkip?: MouseEventHandler<HTMLButtonElement>
}

const VerifyMFAForm: React.FC<VerifyMFAFormProps> = ({
  mfaSetup,
  onSuccess,
  onSkip,
}: VerifyMFAFormProps) => {
  const { createVerifyMfa, verifyMfaError } = useVerifyMfa()
  const { createConfirmSignin, confirmSigninError } = useConfirmSignin()
  const [error, setError] = React.useState<string>('')
  const codeInputRef = useRef<HTMLInputElement>(null)

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (evt) => {
      evt.preventDefault()

      const authCode = codeInputRef.current?.value

      console.log('submitting authCode', authCode)

      if (mfaSetup) {
        return createVerifyMfa({ authCode }, { onSuccess })
      }

      return createConfirmSignin({ authCode }, { onSuccess })
    },
    [createConfirmSignin, createVerifyMfa, mfaSetup, onSuccess],
  )

  useEffect(() => {
    setError(verifyMfaError ?? confirmSigninError)
  }, [confirmSigninError, verifyMfaError])

  return (
    <div className='flex flex-col items-center'>
      <div className='max-w-[330px]'>
        {mfaSetup ? (
          <p className='text-muted text-base mb-0'>
            To set up two-factor authentication, scan the above QR code with an
            authenticator application such as{' '}
            <a
              target='_blank'
              rel='noreferrer'
              href='https://authy.com/guides/twilio/'
            >
              Authy
            </a>
            .
          </p>
        ) : (
          <p className='text-muted text-base mb-0'>
            Enter the code from the two factor app on your mobile device.
          </p>
        )}
      </div>

      <FormMessage type='error'>{error}</FormMessage>

      <form onSubmit={onSubmit}>
        <VerificationCodeInput label='Enter code' ref={codeInputRef} />
        <Button type='submit'>Verify</Button>
        {onSkip && (
          <Button secondary className='mt-3' type='button' onClick={onSkip}>
            Skip
          </Button>
        )}
      </form>
    </div>
  )
}

export default VerifyMFAForm
