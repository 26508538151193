import { ChangeEvent, forwardRef, useCallback, useRef, useState } from 'react'

type VerificationCodeInputProps = {
  label?: string
}

export const VerificationCodeInput = forwardRef<
  HTMLInputElement,
  VerificationCodeInputProps
>(({ label }, ref) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [value, setValue] = useState('')

  const onInputChange = useCallback((evt: ChangeEvent) => {
    const inputs = [...containerRef.current.querySelectorAll('input')]
    const currentIndex = inputs.findIndex((input) => input === evt.target)
    const hasValue = !!(evt.target as HTMLInputElement).value
    const code = inputs
      .map((input) => input.value.trim())
      .filter((x) => x)
      .join('')

    setValue(code)

    if (!hasValue) {
      return
    }

    if (currentIndex < inputs.length - 1) {
      inputs[currentIndex + 1].focus()
    }
  }, [])

  return (
    <div className='my-8'>
      {label && (
        <label className='text-base font-bold mb-3' htmlFor='code'>
          {label}
        </label>
      )}
      <input ref={ref} type='hidden' name='code' value={value} />
      <div
        ref={containerRef}
        className='space-x-2 flex flex-row justify-center'
      >
        {Array(6)
          .fill(null)
          .map((_, i) => (
            <input
              key={i}
              type='text'
              maxLength={1}
              className='rounded-lg bg-neutral-100 flex items-center relative overflow-hidden aspect-[1/1] w-[45px] text-3xl font-bold text-center'
              onChange={onInputChange}
            />
          ))}
      </div>
    </div>
  )
})
