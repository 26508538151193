import { SVGProps } from 'react'

export const TickCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='37'
    height='36'
    viewBox='0 0 37 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect
      x='1.5'
      y='1'
      width='34'
      height='34'
      rx='17'
      stroke='currentColor'
      strokeWidth='2'
    />
    <path
      d='M14.8636 21.8657L10.0909 17.1642L8.5 18.7313L14.8636 25L28.5 11.5672L26.9091 10L14.8636 21.8657Z'
      fill='currentColor'
    />
  </svg>
)
