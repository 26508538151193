import { SiteFooterLinksList } from "./SiteFooterLinksList";
import { SiteFooterLinksListSection } from "./SiteFooterLinksListSection";
import luLogo from "../assets/lu-logo.svg";
import { SiteFooterSocialLinks } from "./SiteFooterSocialLinks";
import { AppleAppStoreButton } from "components/AppStore/AppleAppStoreButton";
import { BaseContainer } from "./BaseContainer";

export const SiteFooter = () => {
  return (
    <footer className="bg-lue-blue-700 text-white relative">
      <BaseContainer size="lg">
        <div
          className="py-10 md:py-16 grid lg:grid-cols-6 gap-y-6 lg:gap-x-6 space-y-3"
        >
          <div className="col-span-2">
            <div className="flex items-center font-medium tracking-wide mb-6 mb:mb-8">
              <img
                className="mr-2"
                src={luLogo}
                width="33"
                height="33"
                alt="Legal Utopia logo"
              />
              Legal Utopia
            </div>
            <p className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold">
              Law is for everyone<span className="text-lue-blue-400">.</span>
            </p>
            <SiteFooterSocialLinks className="hidden lg:flex lg:flex-row space-x-6 mt-10" />
          </div>
          <div className="order-1 lg:order-none col-span-2 lg:col-span-1 grid md:grid-cols-2 lg:grid-cols-1">
            <div>
              <p className="text-2xl xl:text-3xl font-bold mb-6">
                Get the app today.
              </p>
              <AppleAppStoreButton theme="light" />
            </div>
            <SiteFooterSocialLinks className="mt-8 md:mt-0 flex flex-row space-x-6 lg:hidden" />
          </div>
          <SiteFooterLinksListSection title="Legal Utopia">
            <SiteFooterLinksList
              links={[
                {
                  label: 'Who we are',
                  href: '/whoweare',
                },
              ]}
            />
          </SiteFooterLinksListSection>
          <SiteFooterLinksListSection title="Legal">
            <SiteFooterLinksList
              links={[
                {
                  label: 'Equal Opportunities policy',
                  href: '/equal-opportunities',
                },
                {
                  label: 'Employee policy',
                  href: '/employee-privacy',
                },
                {
                  label: 'Marketing policy',
                  href: '/marketing-policy',
                },
                {
                  label: 'Cookies policy',
                  href: '/cookies-policy',
                },
                {
                  label: 'Cookies policy (Mobile App)',
                  href: '/cookies-policy-app',
                },
                {
                  label: 'Privacy policy',
                  href: '/privacy-policy-web',
                },
                {
                  label: 'Privacy policy (Mobile App)',
                  href: '/privacy-policy',
                },
                {
                  label: 'Data retention policy',
                  href: '/data-retention',
                },
                {
                  label: 'Complaints policy',
                  href: '/complaints-policy',
                },
              ]}
            />
            <SiteFooterLinksList
              links={[
                {
                  label: 'Terms of Sale (Consumer)',
                  href: '/terms-of-sale-consumer',
                },
                {
                  label: 'Terms of Use (Business)',
                  href: '/terms-of-use-business',
                },
                {
                  label: 'Terms of Use (Consumer)',
                  href: '/terms-of-use-consumer',
                },
                {
                  label: 'Terms of Sale (Business)',
                  href: '/terms-of-sale-business'
                },
                {
                  label: 'Terms of Sale (Lawyer)',
                  href: '/terms-of-sale-lawyer'
                },
                {
                  label: 'Disclaimer',
                  href: '/disclaimer-general',
                },
                {
                  label: 'Disclaimer Legal Checker',
                  href: '/disclaimer-legal-checker',
                },
                {
                  label: 'Reviews Policy',
                  href: "/reviews-policy"
                },
              ]}
            />
          </SiteFooterLinksListSection>
        </div>
      </BaseContainer>
      <div className="bg-lue-blue-600 py-8">
        <BaseContainer size="lg">
          <p className="text-sm">
            Legal Utopia, Legal Utopia - The A.I Way and LegalCrowd are the trademarks
            and trading names of Legal Utopia Limited, a company registered in England
            and Wales under company number 10909418 operating from and registered address
            Legal Utopia Limited 86-90 Paul Street London EC2A 4NE. (C) Legal Utopia
            Limited 2019-2022. Legal Utopia is patent-pending in the United Kingdom,
            United States of America and Singapore. All Rights Reserved. Google Play
            and the Google Play logo are trademarks of Google LLC.  iPhone and Apple
            are trademarks of Apple Inc., registered in the U.S. and other countries.
            Apple App Store logos are trademarks of Apple Inc., registered in the U.S.
            and other countries. Legal Utopia Limited is registered for VAT under
            registration number: GB 404 391 911.
          </p>
        </BaseContainer>
      </div>
    </footer>
  )
};
