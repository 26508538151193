import { SVGProps } from 'react'

export const ProfileCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='39'
    height='40'
    viewBox='0 0 39 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M19.5026 0.833008C8.9226 0.833008 0.335938 9.41967 0.335938 19.9997C0.335938 30.5797 8.9226 39.1663 19.5026 39.1663C30.0826 39.1663 38.6693 30.5797 38.6693 19.9997C38.6693 9.41967 30.0826 0.833008 19.5026 0.833008ZM19.5026 6.58301C22.6843 6.58301 25.2526 9.15134 25.2526 12.333C25.2526 15.5147 22.6843 18.083 19.5026 18.083C16.3209 18.083 13.7526 15.5147 13.7526 12.333C13.7526 9.15134 16.3209 6.58301 19.5026 6.58301ZM19.5026 33.7997C14.7109 33.7997 10.4751 31.3463 8.0026 27.628C8.0601 23.8138 15.6693 21.7247 19.5026 21.7247C23.3168 21.7247 30.9451 23.8138 31.0026 27.628C28.5301 31.3463 24.2943 33.7997 19.5026 33.7997Z'
      fill='currentColor'
    />
  </svg>
)
