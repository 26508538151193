import { SiteFooter } from "./SiteFooter";
import headerImage from '../assets/WhoWeAreImages/header.png';
import bankIcon from '../assets/bank.svg';
import bankIconWhite from '../assets/WhoWeAreImages/bank.svg';
import addTaskIconWhite from '../assets/WhoWeAreImages/add-task.svg';
import campaignIconWhite from '../assets/WhoWeAreImages/campaign.svg';
import settingsIconWhite from '../assets/WhoWeAreImages/settings.svg';
import accessibilityIconWhite from '../assets/WhoWeAreImages/accessibility.svg';
import leafIconWhite from '../assets/WhoWeAreImages/leaf.svg';
import walletIconWhite from '../assets/WhoWeAreImages/wallet.svg';
import westminsterUni from '../assets/WhoWeAreImages/uni-of-westminster.png';
import angliaRuskinUni from '../assets/WhoWeAreImages/anglia-ruskin-university-aru-logo.png';
import aberdeenUni from '../assets/WhoWeAreImages/university-of-aberdeen-logo.png';
import edinburghUni from '../assets/WhoWeAreImages/edinburgh-uni.png';

import barclaysLogo from '../assets/WhoWeAreImages/barclays.png';
import icoLogo from '../assets/WhoWeAreImages/ico.png';
import lawtechLogo from '../assets/WhoWeAreImages/lawtech.png';
import londonAndPartnersLogo from '../assets/WhoWeAreImages/london-and-partners.png';
import santanderLogo from '../assets/WhoWeAreImages/santander.png';
import wayraukLogo from '../assets/WhoWeAreImages/wayrauk.png';
import panelPhoto from '../assets/WhoWeAreImages/panel-photo.png';
import groupPhoto from '../assets/WhoWeAreImages/group-photo.png';
import fundedBy from '../assets/WhoWeAreImages/funded-by.png';
import fundedByMobile from '../assets/WhoWeAreImages/funded-by-mobile.png';
import vectorBg from '../assets/vector-bg.png';
import deskPhoto from '../assets/WhoWeAreImages/desk-photo.png';
import screenPhoto from '../assets/WhoWeAreImages/screen-photo.png';
import { ContactBar } from "./ContactBar";
import { RoundIcon } from "common/components/RoundIcon";
import { BaseContainer } from "./BaseContainer";

const WhoWeArePage = () => (
  <>
    <header className="flex flex-col lg:flex-row">
      <div className="lg:flex-[50%] lg:order-last">
        <img
          src={headerImage}
          className="object-cover h-full"
          alt="A women demonstrating the app"
          width="1940"
          height="1036"
        />
      </div>
      <div className="lg:flex-[50%] flex flex-col justify-center text-lue-blue-700 px-6 md:px-12 py-14 lg:py-20 bg-lue-purple-200 text-center lg:text-left">
        <h1 className="font-bold text-4xl xl:text-5xl">
          Who we are
        </h1>
        <p className="text-lg mt-4">
          Creating a world where people everywhere feel empowered by technology
          to access law and lawyers.
        </p>
        <ContactBar className="mt-8" />
      </div>
    </header>

    <div className="px-6 md:px-12 bg-lue-purple-400 text-white flex flex-col lg:flex-row justify-between py-8 lg:py-6 items-center text-lg">
      <div className="flex flex-col lg:flex-row items-center">
        <RoundIcon src={bankIcon} size={36} className="bg-white mx-auto" />
        <p className="mt-4 lg:mt-0 lg:ml-3 text-center">
          We envision a world where law is for everyone.
        </p>
      </div>
      <a
        href="/"
        className="underline cursor-pointer mt-4 lg:mt-0">
        Get help today
      </a>
    </div>

    <div className="flex flex-col lg:flex-row py-6 lg:py-20">
      <div className="lg:flex-[50%] px-6 lg:pr-0 lg:pl-12">
        <img
          src={panelPhoto}
          alt="People talking at a panel event"
          width="1012"
          height="599"
        />
      </div>
      <div className="lg:flex-[50%] flex flex-col justify-center">
        <div className="bg-lue-blue-700 mx-12 lg:ml-0 lg:mr-12 px-6 xl:px-20 py-6 xl:py-10 mt-[-25%] lg:mt-0">
          <p className="text-white font-bold text-xl lg:text-3xl xl:text-4xl">
            We’re here to make law work for everyone. Let’s get it done.
          </p>
          <p className="text-basic-blue mt-4 text-lg xl:text-xl">
            <span className="text-white mr-2">-</span>
            Fraser Matcham
          </p>
          <p className="text-white mt-4 font-bold text-lg xl:text-xl">
            Founder
          </p>
        </div>
      </div>
    </div>

    <div className="flex flex-col lg:flex-row">
      <div className="lg:flex-[50%] lg:order-last">
        <img
          src={deskPhoto}
          className="object-cover h-full w-full"
          alt="People discussing the app"
          width="1940"
          height="1036"
        />
      </div>
      <div className="lg:flex-[50%] flex flex-col justify-center text-lue-blue-700 bg-zinc-50 px-6 md:px-12 py-14 lg:py-20 text-center lg:text-left">
        <h2 className="font-bold text-2xl lg:text-3xl xl:text-4xl">
          Established in{' '}
          <span className="text-lue-purple-400">August 2017</span>
        </h2>
        <ul className="mt-10 grid grid-cols-2 gap-6 lg:gap-8 auto-rows-fr	">
          {[
            {
              text: '6 Years Old',
              icon: bankIconWhite,
            },
            {
              text: 'Seedrs Alumni',
              icon: settingsIconWhite,
            },
            {
              text: '£1.1m Funded',
              icon: addTaskIconWhite,
            },
            {
              text: 'Innovate UK-Backed',
              icon: campaignIconWhite,
            },
            {
              text: '263 Shareholders',
              icon: addTaskIconWhite,
            },
            {
              text: '4 Board Directors',
              icon: campaignIconWhite,
            },
          ].map(({ text, icon }) => (
            <li className="px-4 py-6 lg:p-0 bg-lue-purple-100 lg:bg-transparent">
              <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-start">
                <RoundIcon
                  src={icon}
                  size={53}
                  className="bg-lue-purple-400 lg:mr-3 mb-3 lg:mb-0"
                />
                <span className="font-medium text-lg lg:text-xl">
                  {text}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>

    <div className="flex flex-col lg:flex-row">
      <div className="lg:flex-[50%]">
        <img
          src={screenPhoto}
          className="object-cover h-full w-full"
          alt=""
          width="857"
          height="671"
        />
      </div>
      <div className="lg:flex-[50%] flex flex-col justify-center text-lue-blue-700 bg-zinc-50 px-6 md:px-12 xl:px-20 py-20 text-center lg:text-left">
        <h2 className="font-bold text-xl text-lue-purple-400">
          Our Mission
        </h2>
        <p className="font-bold text-black text-2xl	lg:text-3xl xl:text-4xl mt-3">
          We’re here to make a difference, an impact, by making law affordable
          and accessible to everyone.
        </p>
      </div>
    </div>

    <div
      style={{
        backgroundImage: `url('${vectorBg}')`,
        backgroundPosition: '0 10%',
      }}
      className="bg-cover bg-top max-w-full py-20"
    >
      <div className="flex flex-col items-center justify-center h-full">
        <p className="font-bold text-lawBlue text-normal lg:text-2xl mb-3">
          Our Vision
        </p>
        <p className="text-white font-bold text-xl lg:text-3xl xl:text-4xl text-center max-w-[50%]">
          We envision a world where law is for everyone.
        </p>
      </div>
    </div>

    <BaseContainer size="lg" className="pt-20 lg:py-20 flex flex-col items-center">
      <h2 className="font-bold text-2xl lg:text-3xl xl:text-4xl">
        Our Key{' '}
        <span className="text-lue-purple-400">Principles</span>
      </h2>
      <ul className="grid md:grid-cols-3 gap-4 mt-12 lg:mt-16 w-full">
        <li className="p-8 text-xl font-medium bg-lue-green-100 rounded-lg justify-between">
          <div className="flex flex-col h-full">
            <RoundIcon
              src={leafIconWhite}
              size={53}
              className="bg-lue-green-200 mb-[80px] lg:mb-[100px]"
            />
            <span>Sustainability</span>
          </div>
        </li>
        <li className="p-8 text-xl font-medium bg-lue-purple-100 rounded-lg justify-between">
          <div className="flex flex-col h-full">
            <RoundIcon
              src={accessibilityIconWhite}
              size={53}
              className="bg-lue-purple-400 mb-[80px] lg:mb-[100px]"
            />
            <span>Accessibility</span>
          </div>
        </li>
        <li className="p-8 text-xl font-medium bg-lue-cadet-100 rounded-lg justify-between">
          <div className="flex flex-col h-full">
            <RoundIcon
              src={walletIconWhite}
              size={53}
              className="bg-lue-cadet-200 mb-[80px] lg:mb-[100px]"
            />
            <span>Affordability</span>
          </div>
        </li>
      </ul>
    </BaseContainer>

    <div className="relative">
      <p className="font-bold text-2xl lg:text-3xl xl:text-4xl text-white absolute top-[30%] md:top-[20%] w-full text-center">
        Funded by
      </p>
      <img
        src={fundedBy}
        className="object-cover hidden md:block"
        alt="Funders"
      />
      <img
        src={fundedByMobile}
        className="object-cover md:hidden"
        alt="Funders"
      />
    </div>

    <BaseContainer size="lg" className="pt-0 md:pt-12 lg:pt-0 pb-20">
      <div className="max-w-[1440px] mx-auto flex flex-col lg:flex-row">
        <div className="flex-[60%] lg:mr-12 flex flex-col justify-center">
          <p className="font-bold lg:text-xl text-lue-purple-400">
            Delivering Innovation
          </p>
          <p className="text-black font-bold text-2xl lg:text-3xl xl:text-4xl mt-4">
            We’ve delivered industry research in collaboration with leading
            British institutions.
          </p>
          <p className="mt-4">
            Leading UK advancements in the field of computer science and law.
          </p>
        </div>
        <div className="flex-[40%]">
          <ul className="grid auto-rows-fr grid-cols-2 mt-12 gap-4">
            {[
              <img
                src={aberdeenUni}
                alt="Aberdeen University logo"
                width="156"
                height="156"
              />,
              <img
                src={edinburghUni}
                alt="Edinburgh University logo"
                width="180"
                height="42"
              />,
              <img
                src={westminsterUni}
                alt="Westminster University logo"
                width="156"
                height="36"
              />,
              <img
                src={angliaRuskinUni}
                alt="Anglia Ruskin University logo"
                width="180"
                height="180"
              />,
            ].map((node, i) => (
              <li
                key={i}
                className="border py-6 border-4 border-zinc-100 flex items-center justify-center">
                {node}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </BaseContainer>

    <div
      style={{
        backgroundImage: `url('${groupPhoto}')`,
        backgroundPosition: '20% bottom',
      }}
      className="aspect-[1916/843] bg-cover min-h-[500px] max-w-full"
    >
      <div className="flex items-center justify-center h-full">
        <p className="text-white font-bold max-w-[800px] px-6 md:px-12 text-lg sm:text-xl md:text-2xl lg:text-4xl text-center">
          We have represented British LawTech and legal services around the world.
        </p>
      </div>
    </div>

    <BaseContainer size="lg" className="py-20 xl:py-[150px]">
      <h2 className="font-bold text-4xl xl:text-5xl">
        We have been a recognised
        <div className="text-lue-purple-400">pioneer.</div>
      </h2>
      <p className="text-xl mt-4">
        Selected to deliver on our mission and share our vision around the world.
      </p>
      <ul className="grid auto-rows-fr grid-cols-3 grid-cols-2 lg:grid-cols-3 2xl:grid-cols-6 mt-12 gap-4">
        {[
          <img
            src={barclaysLogo}
            alt="Barclays logo"
            width="143"
            height="90"
          />,
          <img
            src={santanderLogo}
            alt="Santander logo"
            width="121"
            height="59"
          />,
          <img
            src={lawtechLogo}
            alt="LawTech logo"
            width="150"
            height="45"
          />,
          <img
            src={londonAndPartnersLogo}
            alt="London and Partners logo"
            width="121"
            height="72"
          />,
          <img
            src={wayraukLogo}
            alt="Wayra logo"
            width="116"
            height="47"
          />,
          <img
            src={icoLogo}
            alt="ICO logo"
            width="140"
            height="48"
          />,
        ].map((node, i) => (
          <li
            key={i}
            className="border py-6 border-4 border-zinc-100 flex items-center justify-center">
            {node}
          </li>
        ))}
      </ul>
    </BaseContainer>
    <SiteFooter />
  </>
);

export default WhoWeArePage;
