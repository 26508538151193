type SiteFooterLinksListProps = {
  links: {
    label: string;
    href: string;
  }[];
}

export const SiteFooterLinksList = ({
  links,
}: SiteFooterLinksListProps) => (
  <ul className="space-y-3">
    {links.map(({ label, href }) => (
      <li key={label}>
        <a
          className="cursor-pointer hover:underline text-base"
          href={href}>
          {label}
        </a>
      </li>
    ))}
  </ul>
);
