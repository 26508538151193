import { Amplify } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import useOffices from 'common/providers/useOffices'
import { useLoadScript } from '@react-google-maps/api'

import Map from './Map'
import {
  SortBy,
  AccountType,
  OfficeSummary,
  BarristerProfile,
  LawyerListObject,
} from 'common/state-type'
import Project from 'common/project'
import { RequestTypes } from 'common/state-type'
import useProfile from 'common/providers/useProfile'
import useFavourite from 'common/providers/useFavourite'
import LawyerProfileCard from './lawyer/LawyerProfileCard'
import {
  FilterSection,
  SearchParam,
  lawyerType,
} from 'components/CWP Sections/components/FilterSection'
import useBarristers from 'common/providers/useBarristers'
import LawfrimProfileCard from './lawfirm/LawfirmProfileCard'
import useLawyerProfiles from 'common/providers/useLawyerProfiles'
import BarristerProfileCard from './barrister/BarristerProfileCard'
import useAreasOfPractice from 'common/providers/useAreasOfPractice'
import { AuthorisationPeriodData, RadiusData } from 'data/DropdownData'
import SortDropdown from 'components/CWP Sections/components/SortDropdown'
import LawfirmCardMbl from 'components/CWP Sections/lawfirm/LawfrimCardMbl'
import { BookingPopup, BookingProfileType, CreateBooking } from './booking'
import PaginationBar from 'components/CWP Sections/components/PaginationBar'
import ProfileCardMobile from 'components/CWP Sections/components/ProfileCardMobile'

const mileToKm = 1.60934 // mile to km
const defaultSelectedRadius = RadiusData[5]
const defaultSelectedAuthPeriod = AuthorisationPeriodData[3]
export type position = {
  lat: number
  lng: number
}

Amplify.configure(Project.cognitoWeb)
const isMobile = Utils.getWindowDimensions().width < 768
type regionCoordinate = {
  latitude: number
  longitude: number
  latitudeDelta: number
  longitudeDelta: number
}

const getSearchType = (type?: string): lawyerType => {
  const lowerCaseType = type?.toLowerCase?.();

  if (
    lowerCaseType === lawyerType.OFFICES ||
    lowerCaseType === lawyerType.SOLICITORS
  ) {
    return lowerCaseType;
  }

  return lawyerType.BARRISTERS;
}

const DashBoard = () => {
  const navigate = useNavigate()
  const params = Utils.fromParam()
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBgM-Dy5rfjIhlmoqsrxnreDSdqGCgtJcY', // Add your API key
  })
  const { profile } = useProfile()
  const { createFavourite, deleteFavourite } = useFavourite()
  const { getAreasOfPractice, areasOfPractice } = useAreasOfPractice()
  const { getOffices, offices: _offices, officesLoading } = useOffices()
  const {
    getBarristers,
    barristers: _barristers,
    barristersLoading,
  } = useBarristers()
  const {
    getLawyerProfiles,
    lawyerProfiles: _lawyers,
    lawyerProfilesLoading,
  } = useLawyerProfiles()
  const isLoading = officesLoading || barristersLoading || lawyerProfilesLoading

  const [activeMarker, setActiveMarker] = useState<string | null>(null)
  const [bookingProfile, setBookingProfile] = useState<BookingProfileType>(null)
  const [mapViewActive, setMapViewActive] = useState(false)
  const initialFilterParams = {
    location: params?.location || '',
    radius: defaultSelectedRadius.value * mileToKm,
    searchType: getSearchType(params?.type),
    lawArea: params?.field ? params?.field.split(',') : undefined,
    authorisationPeriod: defaultSelectedAuthPeriod.value,
    priceFilter: [0, 1000],
  }

  const kilometers = initialFilterParams.radius
  const delta = kilometers / 100
  const defaultRegion = {
    latitude: 51.5072178,
    longitude: -0.1275862,
    latitudeDelta: delta,
    longitudeDelta: delta,
  }
  const [region, setRegion] = useState<regionCoordinate>(defaultRegion)

  // Initially setting the center of map to user's geolocation

  const [filterParams, setFilterParams] =
    useState<SearchParam>(initialFilterParams)

  useEffect(() => {
    getAreasOfPractice({})
  }, [getAreasOfPractice])

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const positionCoordinates = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        latitudeDelta: delta,
        longitudeDelta: delta,
      }
      setRegion(positionCoordinates)
      fetchItems(filterParams, positionCoordinates)
    })
  }

  useEffect(() => {
    if (navigator.permissions && navigator.permissions.query) {
      (
        navigator.permissions?.query?.({ name: 'geolocation' }) ||
        Promise.resolve()
      ).then(function (result) {
        const permission = result.state
        if (permission === 'granted' || permission === 'prompt') {
          getCurrentLocation()
        } else {
          fetchItems(filterParams, defaultRegion)
        }
      })
    } else if (navigator.geolocation) {
      getCurrentLocation()
    } else {
      fetchItems(filterParams, defaultRegion)
    }
  }, [])

  const fetchItems = async (
    searchParams: SearchParam,
    locationCoordinate?: regionCoordinate,
  ) => {
    const localRegion = filterParams.location
      ? await search(searchParams.location)
      : locationCoordinate
      ? locationCoordinate
      : filterParams.location === searchParams.location
      ? region
      : await search(searchParams.location)
    setBookingProfile(null)
    setActiveMarker(null)
    setFilterParams(searchParams)
    let getOfficeParams: RequestTypes['getOffices'] = {
      page: 1,
      page_size: 30,
      areas_of_practice: searchParams.lawArea,
      center: `${localRegion?.latitude},${localRegion?.longitude}`,
      radius_km: searchParams.radius,
      min_price: searchParams.priceFilter[0],
      max_price: searchParams.priceFilter[1],
    }
    if (searchParams.sort_by) {
      getOfficeParams.sort_by = searchParams.sort_by
    }
    if (searchParams.sort_direction) {
      getOfficeParams.sort_direction = searchParams.sort_direction
    }
    switch (searchParams.searchType) {
      case 'solicitors':
        getLawyerProfiles(getOfficeParams)
        break
      case 'barristers':
        getBarristers(getOfficeParams)
        break
      case 'offices':
        getOffices({
          ...getOfficeParams,
          authorisation_period: searchParams.authorisationPeriod,
        })
    }
  }

  const handleSortBy = (sortBy: SortBy, direction: 'ASC' | 'DESC') => {
    fetchItems({ ...filterParams, sort_by: sortBy, sort_direction: direction })
  }

  const onClearSearch = () => {
    setFilterParams(initialFilterParams)
    getCurrentLocation()
  }

  const search = async (locationText: string) => {
    let url = `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${encodeURIComponent(
      locationText,
    )}&inputtype=textquery&fields=formatted_address,name,place_id,geometry/location&key=AIzaSyC33pnXQZu7T68yF3ScQSGIjxeBB1nw5fI&locationbias=point:52,0`
    const locationJSON = await fetch(
      `${Project.gaApi}map-search?url=${encodeURIComponent(url)}`,
      {
        method: 'GET',
      },
    )
    const location = await locationJSON.json()
    const cand = location?.candidates?.[0]?.geometry?.location
    if (cand?.lat && cand?.lng) {
      setRegion({
        latitude: cand.lat,
        longitude: cand.lng,
        latitudeDelta: delta,
        longitudeDelta: delta,
      })
      return {
        latitude: cand.lat,
        longitude: cand.lng,
        latitudeDelta: delta,
        longitudeDelta: delta,
      }
    } else {
      setRegion(defaultRegion)
      return defaultRegion
    }
  }

  const barristers = filterParams.searchType === 'barristers' && _barristers
  const offices = filterParams.searchType === 'offices' && _offices
  const lawyers = filterParams.searchType === 'solicitors' && _lawyers

  const handleFavPress = (
    item: LawyerListObject | BarristerProfile | OfficeSummary,
    accountType: AccountType,
  ) => {
    if (profile?.id) {
      if (item.favourite_id) {
        const data = {
          id: item.favourite_id,
          type: accountType,
        }
        deleteFavourite(data)
      } else {
        const type = Utils.getUserType(accountType)
        createFavourite({ [type]: item.id })
      }
    } else {
      navigate('/login')
    }
  }

  const handleBookPress = (
    item: LawyerListObject | BarristerProfile,
    accountType: AccountType,
  ) => {
    if (profile?.id) {
      setBookingProfile({ profile: item, lawyerType: accountType })
    } else {
      navigate('/login')
    }
  }

  const getAreaNames = (areas: string[]) => {
    return areasOfPractice
      ?.filter((v) => areas?.includes(v.id))
      .map((v) => v.name)
  }

  const renderList = () => {
    if (barristers) {
      return (
        <>
          {barristers.results?.map((item) => (
            <div key={item.id}>
              <ProfileCardMobile
                areas={getAreaNames(filterParams.lawArea)}
                onMouseEnter={() => setActiveMarker(item.id)}
                latitude={region.latitude}
                longitude={region.longitude}
                data={item}
                onFavPress={() => handleFavPress(item, AccountType.BARRISTER)}
                onBookNow={() => handleBookPress(item, AccountType.BARRISTER)}
                lawyerType='barrister'
                cardStyle={'mt-4'}
              />
              <BarristerProfileCard
                className='hidden md:flex flex-1'
                areas={getAreaNames(filterParams.lawArea)}
                onMouseEnter={() => setActiveMarker(item.id)}
                latitude={region.latitude}
                longitude={region.longitude}
                data={item}
                onFavPress={() => handleFavPress(item, AccountType.BARRISTER)}
                onBookNow={() => handleBookPress(item, AccountType.BARRISTER)}
                cardStyle={'mt-4'}
              />
            </div>
          ))}
          {(barristers.next || barristers.previous) && (
            <PaginationBar
              onClick={(e) => {
                if (e.isNext) {
                  getBarristers({ link: barristers.next })
                }
                if (e.isPrevious) {
                  getBarristers({ link: barristers.previous })
                }
              }}
            />
          )}
          {barristers.results.length === 0 && (
            <div className='flex flex-1 items-center justify-center text-xl'>
              No Barrister Found
            </div>
          )}
        </>
      )
    } else if (lawyers) {
      return (
        <>
          {lawyers.results?.map((item, index) => (
            <div key={item.id}>
              <ProfileCardMobile
                areas={getAreaNames(filterParams.lawArea)}
                onMouseEnter={() => setActiveMarker(item.id)}
                latitude={region.latitude}
                longitude={region.longitude}
                data={item}
                onFavPress={() => handleFavPress(item, AccountType.LAWYER)}
                onBookNow={() => handleBookPress(item, AccountType.LAWYER)}
                lawyerType='solicitor'
                cardStyle={'mt-4'}
              />
              <LawyerProfileCard
                className='hidden md:flex flex-1'
                areas={getAreaNames(filterParams.lawArea)}
                onMouseEnter={() => setActiveMarker(item.id)}
                latitude={region.latitude}
                longitude={region.longitude}
                data={item}
                onFavPress={() => handleFavPress(item, AccountType.LAWYER)}
                onBookNow={() => handleBookPress(item, AccountType.LAWYER)}
                cardStyle={'mt-4'}
              />
            </div>
          ))}
          {(lawyers.next || lawyers.previous) && (
            <PaginationBar
              onClick={(e) => {
                if (e.isNext) {
                  getLawyerProfiles({ link: lawyers.next })
                }
                if (e.isPrevious) {
                  getLawyerProfiles({ link: lawyers.previous })
                }
              }}
            />
          )}
          {lawyers.results?.length === 0 && (
            <div className='flex flex-1 items-center justify-center text-xl'>
              No Lawyer Found
            </div>
          )}
        </>
      )
    } else if (offices) {
      return (
        <>
          {offices.results?.map((item) => (
            <div key={item.id}>
              <LawfirmCardMbl
                areas={getAreaNames(filterParams.lawArea)}
                onMouseEnter={() => setActiveMarker(item.id)}
                latitude={region.latitude}
                longitude={region.longitude}
                data={item}
                onFavPress={() => handleFavPress(item, AccountType.BUSINESS)}
                onBookNow={() => navigate(`/lawfirms/${item.id}/members`)}
                cardStyle={'mt-4'}
              />
              <LawfrimProfileCard
                className='hidden md:flex flex-1'
                areas={getAreaNames(filterParams.lawArea)}
                key={item.id}
                onMouseEnter={() => setActiveMarker(item.id)}
                latitude={region.latitude}
                longitude={region.longitude}
                data={item}
                onFavPress={() => handleFavPress(item, AccountType.BUSINESS)}
                onBookNow={() => navigate(`/lawfirms/${item.id}/members`)}
                cardStyle={'mt-4'}
              />
            </div>
          ))}
          {(offices.next || offices.previous) && (
            <PaginationBar
              onClick={(e) => {
                if (e.isNext) {
                  getOffices({ link: offices.next })
                }
                if (e.isPrevious) {
                  getOffices({ link: offices.previous })
                }
              }}
            />
          )}
          {offices.results.length === 0 && (
            <div className='flex flex-1 items-center justify-center text-xl'>
              No Lawfirm Found
            </div>
          )}
        </>
      )
    } else if (isLoading) {
      return (
        <div className='flex h-[100%] items-center text-xl justify-center'>
          Loading....
        </div>
      )
    }
    return <></>
  }

  return (
    <>
      <FilterSection
        initialFilterParams={initialFilterParams}
        isLoading={isLoading}
        toggleMapView={() => setMapViewActive(!mapViewActive)}
        mapViewActive={mapViewActive}
        handleSearch={fetchItems}
        handleClearSearch={onClearSearch}
      />

      <div className='flex flex-row w-full bg-white'>
        {!mapViewActive && (
          <div className='flex flex-col relative overflow-scroll w-full md:w-[42.29vw] h-[100vh] pb-8'>
            {(barristers || lawyers || offices) && (
              <SortDropdown
                getSortBy={handleSortBy}
                listType={filterParams.searchType}
                isLoading={isLoading}
              />
            )}
            <div className='py-4 flex flex-1  w-full flex-col px-6 md:px-0'>
              {renderList()}
            </div>
          </div>
        )}
        {bookingProfile && (
          <BookingPopup>
            <CreateBooking
              lawyer={bookingProfile.profile}
              lawyerType={bookingProfile.lawyerType}
              handleClose={() => setBookingProfile(null)}
            />
          </BookingPopup>
        )}

        {isLoaded && (!isMobile || mapViewActive) ? (
          <Map
            setActiveMarker={setActiveMarker}
            activeMarker={activeMarker}
            center={{
              lat: region.latitude,
              lng: region.longitude,
            }}
            lawyers={lawyers}
            barristers={barristers}
            offices={offices}
            handleFavPress={(item) =>
              handleFavPress(
                item,
                barristers
                  ? AccountType.BARRISTER
                  : lawyers
                  ? AccountType.LAWYER
                  : AccountType.BUSINESS,
              )
            }
            handleBookNowPress={(item) =>
              handleBookPress(
                item,
                barristers
                  ? AccountType.BARRISTER
                  : lawyers
                  ? AccountType.LAWYER
                  : AccountType.BUSINESS,
              )
            }
          />
        ) : null}
      </div>
    </>
  )
}

export default DashBoard
