import { useState } from 'react'
import { InputGroup, InputGroupProps } from './InputGroup'
import { VisibilityOffIcon } from '../Icons/VisibilityOffIcon'
import { VisibilityIcon } from '../Icons/VisibilityIcon'

export const PasswordInputGroup = (props: InputGroupProps) => {
  const [isVisible, setIsVisible] = useState(false)

  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  const Icon = isVisible ? VisibilityIcon : VisibilityOffIcon

  return (
    <InputGroup
      {...props}
      type={isVisible ? 'text' : 'password'}
      imgAfter={
        <button
          type='button'
          className='flex opacity-50'
          onClick={toggleVisibility}
        >
          <Icon width={24} height={24} />
        </button>
      }
    />
  )
}
