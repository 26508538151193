import { SVGProps } from 'react'

export const PadlockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='15'
    height='20'
    viewBox='0 0 15 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M7.5 14.875C8.4625 14.875 9.25 14.0875 9.25 13.125C9.25 12.1625 8.4625 11.375 7.5 11.375C6.5375 11.375 5.75 12.1625 5.75 13.125C5.75 14.0875 6.5375 14.875 7.5 14.875ZM12.75 7H11.875V5.25C11.875 2.835 9.915 0.875 7.5 0.875C5.085 0.875 3.125 2.835 3.125 5.25V7H2.25C1.2875 7 0.5 7.7875 0.5 8.75V17.5C0.5 18.4625 1.2875 19.25 2.25 19.25H12.75C13.7125 19.25 14.5 18.4625 14.5 17.5V8.75C14.5 7.7875 13.7125 7 12.75 7ZM4.7875 5.25C4.7875 3.75375 6.00375 2.5375 7.5 2.5375C8.99625 2.5375 10.2125 3.75375 10.2125 5.25V7H4.7875V5.25ZM12.75 17.5H2.25V8.75H12.75V17.5Z'
      fill='currentColor'
    />
  </svg>
)
