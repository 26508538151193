import React, { useContext, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useAuth } from 'common/providers/useAuth'
import Project from 'common/project'
import { EmailIcon } from '../Icons/EmailIcon'
import { PadlockIcon } from '../Icons/PadlockIcon'
import { SocialSignInButtons } from './SocialSignInButtons'
import { FooterText } from './FooterText'
import { Button } from './Button'
import { InputGroup } from '../Inputs/InputGroup'
import { CheckboxGroup } from '../Inputs/CheckboxGroup'
import { FormPageWrapper } from './FormPageWrapper'
import { FormMessage } from './FormMessage'
import { PasswordInputGroup } from '../Inputs/PasswordInputGroup'
import { ProfileCircleIcon } from '../Icons/ProfileCircleIcon'
import { ProfileFilledIcon } from '../Icons/ProfileFilledIcon'
import { AuthPageContext } from '../../providers/AuthPageProvider'
import { encodeAuthState } from 'common/utils/auth'

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Email address is required'),
  password: yup.string().required('Password is required'),
  policies: yup
    .boolean()
    .oneOf([true], 'Please accept the terms and privacy policy'),
})

const SignUpPage = () => {
  const { hasSocialSignIn, hasNameFields, accountType, hasMarketing } =
    useContext(AuthPageContext)

  const [error, setError] = useState()
  const { register } = useAuth()
  const { handleBlur, handleSubmit, handleChange, errors, setTouched, values } =
    useFormik({
      validateOnBlur: false,
      validateOnChange: false,
      initialValues: {
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        account_type: '',
        policies: false,
      },
      validationSchema: schema,
      onSubmit: (values) => {
        register(
          {
            ...values,
            account_type: accountType,
            accepted_marketing: hasMarketing,
          },
          {
            onSuccess: () => {
              document.location = `${
                document.location.origin
              }/verify-email?state=${encodeAuthState({
                email: values.email,
                password: values.password,
              })}`
            },
            onError: setError,
          },
        )
      },
    })

  const onSubmit = (e) => {
    const newTouched = {}
    Object.keys(errors).map((v) => {
      newTouched[v] = true
    })
    setTouched(newTouched)
    handleSubmit(e)
  }

  return (
    <FormPageWrapper title='Create Account' Icon={ProfileCircleIcon}>
      <FormMessage type='error'>{error}</FormMessage>
      <form onSubmit={onSubmit}>
        {hasNameFields && (
          <>
            <InputGroup
              id='first-name'
              label='First Name'
              name='first_name'
              theme='grey'
              imgBefore={<ProfileFilledIcon />}
              onChange={handleChange}
              value={values.first_name}
              placeholder='First Name'
              onBlur={handleBlur}
              error={errors.first_name}
            />
            <InputGroup
              id='last-name'
              label='Last Name'
              name='last_name'
              theme='grey'
              imgBefore={<ProfileFilledIcon />}
              onChange={handleChange}
              value={values.last_name}
              placeholder='Last Name'
              className='my-5'
              onBlur={handleBlur}
              error={errors.last_name}
            />
          </>
        )}
        <InputGroup
          id='email'
          label='Email'
          name='email'
          theme='grey'
          imgBefore={<EmailIcon />}
          onChange={handleChange}
          value={values.email}
          placeholder='Email Address'
          className='my-5'
          onBlur={handleBlur}
          error={errors.email}
        />
        <PasswordInputGroup
          id='password'
          label='Password'
          name='password'
          theme='grey'
          imgBefore={<PadlockIcon />}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.password}
          value={values.password}
          className='my-5'
          type='new-password'
          placeholder='Password'
        />
        <CheckboxGroup
          id='terms'
          className='my-5 w-full flex flex-row justify-center'
          label={
            <span className='text-base text-black'>
              I agree with the
              <a
                href={new URL('/terms-of-use-consumer', Project.cwp).href}
                className='text-lue-purple-400 inline-block px-1'
              >
                Terms
              </a>
              and
              <a
                href={new URL('/privacy-policy-web', Project.cwp).href}
                className='text-lue-purple-400 inline-block px-1'
              >
                Privacy policy
              </a>
            </span>
          }
          name='policies'
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.policies}
          checked={values.policies}
        />

        <Button type='submit'>Sign Up</Button>
      </form>

      <div className='mt-4'>
        {hasSocialSignIn && (
          <>
            <p className='text-center mb-4'>or</p>
            <SocialSignInButtons />
          </>
        )}
        <FooterText
          text='Already have an account?'
          linkHref='/login'
          linkText='Log In'
        />
      </div>
    </FormPageWrapper>
  )
}

export default SignUpPage
