import { useAuth } from '../../providers/useAuth'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { Auth } from 'aws-amplify'
import { ComponentType, SVGProps } from 'react'

type SocialSignInButtonProps = {
  children: string
  provider: CognitoHostedUIIdentityProvider
  Icon: ComponentType<SVGProps<SVGSVGElement>>
}

export const SocialSignInButton = ({
  provider,
  children,
  Icon,
}: SocialSignInButtonProps) => {
  const { oauth } = useAuth()

  const onClick = () => {
    oauth({
      authorize: async () => Auth.federatedSignIn({ provider }),
    })
  }

  return (
    <button
      onClick={onClick}
      type='button'
      className='rounded-full border border-black text-black text-base font-medium py-2 px-8 whitespace-nowrap mb-4'
    >
      <div className='flex flex-row item-center justify-center'>
        <div className='w-[14px] mr-2 flex items-center'>
          <Icon width='14' />
        </div>
        <span>{children}</span>
      </div>
    </button>
  )
}
