import { LuLogo } from '../Icons/LuLogo'
import { LinkContext } from '../../providers/LinkProvider'
import { useContext } from 'react'

type NavbarLogoProps = {
  href?: string
}

export const NavbarLogo = ({ href = '/' }: NavbarLogoProps) => {
  const { Link } = useContext(LinkContext)

  return (
    <Link href={href}>
      <div className='flex items-center font-medium tracking-wide text-xl md:text-3xl font-semibold text-lue-blue-800 max-w-[40px] md:max-w-[50px] leading-none md:whitespace-nowrap cursor-pointer'>
        <LuLogo className='mr-2 min-w-[50px]' width='50' height='50' />
        Legal Utopia
      </div>
    </Link>
  )
}
