import { InputHTMLAttributes } from 'react'
import cn from 'classnames'

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  isLarge?: boolean
  isDropdown?: boolean
}

const getCursorClassname = (disabled?: boolean, isDropdown?: boolean) => {
  if (disabled) {
    return 'cursor-not-allowed'
  }

  if (isDropdown) {
    return 'cursor-pointer'
  }

  return 'cursor-text'
}

export const Input = ({
  className,
  isLarge,
  disabled,
  isDropdown,
  ...props
}: InputProps) => (
  <input
    className={cn(
      'bg-transparent py-2.5 w-full outline-none placeholder:text-b33 text-lg text-b33 font-normal',
      getCursorClassname(disabled, isDropdown),
      isLarge ? 'lg:py-7 px-5' : 'px-4',
      className,
    )}
    disabled={disabled}
    {...props}
  />
)
