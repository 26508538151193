import cn from 'classnames';
import Constants from "../../../common/utils/constants";
import phone from '../assets/phone.svg';

type ContactBarProps = {
  className?: string;
};

export const ContactBar = ({
  className,
}: ContactBarProps) => (
  <div className={cn(
    'flex flex-col lg:flex-row items-center justify-cente space-y-5 lg:space-y-0',
    className,
  )}>
    <div className="text-lg flex justify-center">
      <img src={phone} width="23" height="23" alt="" />
      <div className="whitespace-nowrap mx-2">
        Call
        <span className="ml-2 font-bold">020 3918 5630</span>
      </div>
    </div>
    <span className="text-center font-medium mx-6 text-lg">
      OR
    </span>
    <div>
      <a
        href={`mailto:${Constants.supportEmail}`}
        className="rounded-full bg-lue-purple-400 text-white text-lg py-2 px-8 w-full flex items-center justify-center whitespace-nowrap transition-colors hover:bg-lue-purple-500"
      >
        Email us
      </a>
    </div>
  </div>
)