import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { SocialSignInButton } from './SocialSignInButton'
import { GoogleIcon } from '../Icons/GoogleIcon'
import { AppleIcon } from '../Icons/AppleIcon'

export const SocialSignInButtons = () => (
  <div className='flex flex-col'>
    <SocialSignInButton
      provider={CognitoHostedUIIdentityProvider.Google}
      Icon={GoogleIcon}
    >
      Sign In with Google
    </SocialSignInButton>

    <SocialSignInButton
      provider={CognitoHostedUIIdentityProvider.Apple}
      Icon={AppleIcon}
    >
      Sign In with Apple
    </SocialSignInButton>
  </div>
)
