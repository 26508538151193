import React, { useState } from 'react'
import { useAuth } from '../../providers/useAuth'
import { InputGroup } from '../Inputs/InputGroup'
import { FormPageWrapper } from './FormPageWrapper'
import { FormMessage } from './FormMessage'
import { Button } from './Button'
import { EmailIcon } from '../Icons/EmailIcon'
import { KeyIcon } from '../Icons/KeyIcon'

const ForgottenPasswordPage = () => {
  const { userLoading, resetPassword } = useAuth()
  const [userError, setError] = useState<boolean>(false)
  const [resetPEmail, setResetPEmail] = useState('')

  const onSendCodeClick = () => {
    setError(null)
    resetPassword(
      { email: resetPEmail },
      {
        onSuccess: () => {
          document.location.href = `/reset-password?email=${encodeURIComponent(
            resetPEmail,
          )}`
        },
        onError: setError,
      },
    )
  }

  return (
    <FormPageWrapper title='Forgotten Password' Icon={KeyIcon}>
      <FormMessage type='error'>{userError}</FormMessage>
      <form
        className='space-y-6'
        onSubmit={Utils.preventDefault}
        autoComplete='off'
      >
        <InputGroup
          id='email'
          name='email'
          imgBefore={<EmailIcon />}
          theme='grey'
          label='Email'
          placeholder='Email Address'
          onChange={(e) => setResetPEmail(e.target.value)}
        />
        <Button
          type='submit'
          disabled={userLoading || resetPEmail.length < 6}
          onClick={onSendCodeClick}
        >
          Send
        </Button>
        <p className='text-black font-normal mt-2 text-center text-base'>
          Remember your password?
          <span>
            <a
              href='./login'
              className='text-lue-purple-400 underline inline-block pl-1'
            >
              Sign In
            </a>
          </span>
        </p>
      </form>
    </FormPageWrapper>
  )
}

export default ForgottenPasswordPage
