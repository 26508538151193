import { SVGProps } from 'react'

export const EmailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='19'
    height='15'
    viewBox='0 0 19 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M16.5 0.5H2.5C1.5375 0.5 0.75875 1.2875 0.75875 2.25L0.75 12.75C0.75 13.7125 1.5375 14.5 2.5 14.5H16.5C17.4625 14.5 18.25 13.7125 18.25 12.75V2.25C18.25 1.2875 17.4625 0.5 16.5 0.5ZM16.5 4L9.5 8.375L2.5 4V2.25L9.5 6.625L16.5 2.25V4Z'
      fill='currentColor'
    />
  </svg>
)
